import React from "react";
import { graphql } from "gatsby";

import Sidebar from "../components/Sidebar";
import Tag from "../components/Tag";
import SEO from "../components/SEO";

// eslint-disable-next-line react/prop-types
const TagPage = ({ data }) => {
  const { allStrapiArticle } = data;

  const mapping = {};
  console.log(allStrapiArticle);
  allStrapiArticle.edges.forEach(({ node }) => {
    node.categories.forEach((tag) => {
      const { name } = tag;
      if (mapping[name]) {
        mapping[name] += 1;
      } else {
        mapping[name] = 1;
      }
    });
  });

  const tags = Array.from(Object.keys(mapping)).sort(
    (b, a) => mapping[a] - mapping[b],
  );

  return (
    <div className="container">
      <div
        className="row"
        style={{
          margin: 15
        }}
      >
        <Sidebar />

        <div className="col order-2">
          {tags.map(item => (
            <Tag name={item} key={item} count={mapping[item]} />
          ))}
        </div>
      </div>
      <SEO
        title="Tags"
        url="/tags/"
        siteTitleAlt="HellCatVN's Blog"
        isPost={false}
        description="Tags Page"
        image="https://i.imgur.com/M795H8A.jpg"
      />
    </div>
  );
};

export default TagPage;

export const pageQuery = graphql`
  query getAllTags {
    allStrapiArticle {
      edges {
        node {
          categories {
            name
          }
        }
      }
    }
  }
`;
